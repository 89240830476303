import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
export interface ExpireDateState {
    expireDate: string | null;
}

// Define the initial state using that type
const initialState: ExpireDateState = {
    expireDate: null,
};

export const searchExpireDate = createSlice({
    name: 'searchExpireDate',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        saveSearchExpireDate: (state, action: PayloadAction<string>) => {
            state.expireDate = action.payload;
        },
        clearSearchExpireDate: state => {
            state.expireDate = null;
        },
    },
});

export const { saveSearchExpireDate } = searchExpireDate.actions;

export default searchExpireDate.reducer;
