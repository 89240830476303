import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupportedCurrency } from '../services/flights/flight.types';

// Define a type for the slice state
interface currencyState {
    currency: SupportedCurrency;
}

// Define the initial state using that type
const initialState: currencyState = {
    currency: SupportedCurrency.USD,
};

export const currencySlice = createSlice({
    name: 'currency',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        currencySave: (state, action: PayloadAction<SupportedCurrency>) => {
            state.currency = action.payload;
        },
        currencyClear: state => {
            state.currency = SupportedCurrency.USD;
        },
    },
});

export const { currencySave, currencyClear } = currencySlice.actions;

export default currencySlice.reducer;
