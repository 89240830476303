import { ThemeProvider } from '@mui/material/styles';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';
import { getCache, getTheme } from '@travel-platform/commons/src/components/common/theme/theme';
import '@travel-platform/commons/src/fonts/Vazirmatn/Vazirmatn.css';
import '@travel-platform/commons/src/fonts/icons/style.css';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import Script from 'next/script';
import NProgress from 'nprogress';
import { useCallback, useEffect } from 'react';
import { Provider } from 'react-redux';
import 'swiper/css';
import { PROFILE_PROJECT, ProfileProjectEnum, SITE_URL } from '../components/common/constant/constant';
import Global from '../components/common/global/global';
import '../components/common/style/global-style.css';
import '../components/common/style/libraries/multi-date-picker/multi-date-picker-mobile.scss';
import '../components/common/style/libraries/multi-date-picker/multi-date-picker.scss';
import '../components/common/style/libraries/nprogress.scss';
import '../components/hotels/search-fields-hotels/components/dialog-details-hotel/dialog-details-hotel.css';
import '../components/landing/landing.css';
import { store } from '../redux/store';
import 'moment/locale/ar';
// eslint-disable-next-line @next/next/no-server-import-in-page
import { userAgentFromString } from 'next/server';
import mediaQuery from 'css-mediaquery';
import { AppCacheProvider } from '@mui/material-nextjs/v13-pagesRouter';
import App from 'next/app';
import JsonLdOrganization from 'components/json-lds/organization';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

// Environment
const CHATWOOT_WEBSITE_TOKEN = process.env.NEXT_PUBLIC_CHATWOOT_WEBSITE_TOKEN || null;
const CHATWOOT_WEBSITE_URL = process.env.NEXT_PUBLIC_CHATWOOT_WEBSITE_URL || null;

NProgress.configure({ showSpinner: true, easing: 'ease', speed: 200 });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const isBrowser = typeof document !== 'undefined';

const MyApp = (props: any): JSX.Element => {
    const { Component, pageProps, userAgentValue } = props;
    const router = useRouter();
    const language = useLanguage();

    const ssrMatchMedia = useCallback(
        (query: any) => {
            const deviceType = userAgentFromString(userAgentValue).device.type || 'desktop';

            return {
                matches: mediaQuery.match(query, {
                    width: deviceType === 'mobile' ? '0px' : '1024px',
                }),
            };
        },
        [userAgentValue]
    );
    useEffect(() => {
        const preloader = document.getElementById('preloader');

        if (isBrowser) {
            preloader?.remove();
        }
    }, []);

    /*
    We should set direction for body to mui components
    get right direction style.
    We should use useEffect for SSR compatiblity.
  */

    useEffect(() => {
        document.body.setAttribute('dir', language.direction);
    }, [language.direction]);

    const isProduction = PROFILE_PROJECT === ProfileProjectEnum.PRODUCTION;
    const isVisitOurIran = PROFILE_PROJECT === 'VISITOURIRAN';

    const safratMedia = (
        <>
            <meta
                name='application-name'
                content='Safrat'
            />
            <meta
                name='apple-mobile-web-app-capable'
                content='yes'
            />
            <meta
                name='apple-mobile-web-app-status-bar-style'
                content='default'
            />
            <meta
                name='apple-mobile-web-app-title'
                content='Safrat'
            />
            <meta
                name='description'
                content='Safrat is an app to book hotel and reserve air plane ticket'
            />
            <meta
                name='format-detection'
                content='telephone=no'
            />
            <meta
                name='mobile-web-app-capable'
                content='yes'
            />
            <meta
                name='msapplication-config'
                content='/safrat/icons/browserconfig.xml'
            />
            <meta
                name='msapplication-TileColor'
                content='#fff'
            />
            <meta
                name='msapplication-tap-highlight'
                content='no'
            />
            <meta
                name='theme-color'
                content='#ffffff'
            />

            <link
                rel='apple-touch-icon'
                href='/safrat/icons/apple-touch-icon.png'
            />
            <link
                rel='apple-touch-icon'
                sizes='152x152'
                href='/safrat/icons/AppIcon.appiconset/Icon-App-76x76@2x.png'
            />
            <link
                rel='apple-touch-icon'
                sizes='180x180'
                href='/safrat/icons/AppIcon.appiconset/Icon-App-60x60@2x.png'
            />
            {/* <link rel="apple-touch-icon" sizes="167x167" href="/safrat/icons/touch-icon-ipad-retina.png" /> */}

            <link
                rel='icon'
                type='image/png'
                sizes='32x32'
                href='/safrat/icons/favicon-32x32.png'
            />
            <link
                rel='icon'
                type='image/png'
                sizes='16x16'
                href='/safrat/icons/favicon-16x16.png'
            />
            <link
                rel='manifest'
                href='/safrat/manifest.json'
            />
            <link
                rel='mask-icon'
                href='/safrat/icons/maskable_icon_x192.png'
                color='#fff'
            />
            <link
                rel='shortcut icon'
                href='/safrat/icons/favicon.ico'
            />
            <meta
                name='twitter:card'
                content='summary'
            />
            <meta
                name='twitter:url'
                content='https://safrat.me'
            />
            <meta
                name='twitter:title'
                content='Safrat'
            />
            <meta
                name='twitter:description'
                content='Safrat is an app to book hotel and reserve air plane ticket'
            />
            <meta
                name='twitter:image'
                content='https://safrat.me/safrat/icons/android/mipmap-xxxhdpi/ic_launcher.png'
            />
            <meta
                property='og:type'
                content='website'
            />
            <meta
                property='og:title'
                content='Safrat'
            />
            <meta
                property='og:description'
                content='Safrat is an app to book hotel and reserve air plane ticket'
            />
            <meta
                property='og:site_name'
                content='Safrat'
            />
            <meta
                property='og:url'
                content='https://safrat.me'
            />
            <meta
                property='og:image'
                content='https://safrat.me/icons/safrat/apple-touch-icon.png'
            />
        </>
    );
    const visitMedia = (
        <>
            <meta
                name='application-name'
                content='VisitOurIran'
            />
            <meta
                name='apple-mobile-web-app-capable'
                content='yes'
            />
            <meta
                name='apple-mobile-web-app-status-bar-style'
                content='default'
            />
            <meta
                name='apple-mobile-web-app-title'
                content='VisitOurIran'
            />

            <meta
                name='format-detection'
                content='telephone=no'
            />
            <meta
                name='mobile-web-app-capable'
                content='yes'
            />
            <meta
                name='msapplication-config'
                content='/visitOurIran/icons/browserconfig.xml'
            />
            <meta
                name='msapplication-TileColor'
                content='#fff'
            />
            <meta
                name='msapplication-tap-highlight'
                content='no'
            />
            <meta
                name='theme-color'
                content='#ffffff'
            />

            <link
                rel='apple-touch-icon'
                href={`${process.env.NEXT_PUBLIC_BASE_PATH}/visitOurIran/icons/apple-touch-icon.png`}
            />
            <link
                rel='apple-touch-icon'
                sizes='152x152'
                href={`${process.env.NEXT_PUBLIC_BASE_PATH}/visitOurIran/icons/apple-icon-152x152.png`}
            />
            <link
                rel='apple-touch-icon'
                sizes='180x180'
                href={`${process.env.NEXT_PUBLIC_BASE_PATH}/visitOurIran/icons/apple-icon-180x180.png`}
            />
            {/* <link rel="apple-touch-icon" sizes="167x167" href="/visitOurIran/icons/touch-icon-ipad-retina.png" /> */}

            <link
                rel='icon'
                type='image/png'
                sizes='32x32'
                href={`${process.env.NEXT_PUBLIC_BASE_PATH}/visitOurIran/icons/favicon-32x32.png`}
            />
            <link
                rel='icon'
                type='image/png'
                sizes='16x16'
                href={`${process.env.NEXT_PUBLIC_BASE_PATH}/visitOurIran/icons/favicon-16x16.png`}
            />
            <link
                rel='manifest'
                href={`${process.env.NEXT_PUBLIC_BASE_PATH}/visitOurIran/manifest.json`}
            />
            <link
                rel='mask-icon'
                href={`${process.env.NEXT_PUBLIC_BASE_PATH}/visitOurIran/icons/maskable_icon_x192.png`}
                color='#fff'
            />
            <link
                rel='shortcut icon'
                href={`${process.env.NEXT_PUBLIC_BASE_PATH}/visitOurIran/icons/favicon.ico`}
            />
        </>
    );

    if (typeof window !== 'undefined') {
        // checks that we are client-side
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
            api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
            person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
            loaded: posthog => {
                if (process.env.NODE_ENV === 'development') posthog.debug(); // debug mode in development
            },
        });
    }

    return (
        <>
            <Script
                strategy='afterInteractive'
                id='hotjar'
                type='text/javascript'>
                {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${process.env.NEXT_PUBLIC_HOTJAR_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
            </Script>
            {isVisitOurIran && (
                <Script
                    id='clarity'
                    type='text/javascript'>
                    {`(function(c,l,a,r,i,t,y){
                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                            t=l.createElement(r);t.async=1;t.src="${process.env.NEXT_PUBLIC_CLARITY_URL}"+i;
                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                            })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID}");`}
                </Script>
            )}
            <Script id='google-manager'>
                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': 
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], 
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); 
                        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM}');`}
            </Script>
            {!!CHATWOOT_WEBSITE_URL && !!CHATWOOT_WEBSITE_TOKEN && (
                <Script
                    id='chatwoot-script'
                    strategy='afterInteractive'>
                    {`(function(d,t) { var BASE_URL="${CHATWOOT_WEBSITE_URL}"; var g=d.createElement(t),s=d.getElementsByTagName(t)[0]; g.src=BASE_URL+"/packs/js/sdk.js"; g.defer = true; g.async = true; s.parentNode.insertBefore(g,s); g.onload=function(){ window.chatwootSDK.run({ websiteToken: '${CHATWOOT_WEBSITE_TOKEN}', baseUrl: BASE_URL }) } })(document,"script");`}
                </Script>
            )}

            <Head>
                <meta charSet='utf-8' />
                <meta
                    name='viewport'
                    content='initial-scale=1.0, width=device-width'
                />

                {isVisitOurIran ? visitMedia : safratMedia}
                {router.locales?.map((locale, index) => (
                    <link
                        key={index}
                        rel='alternate'
                        hrefLang={locale}
                        href={SITE_URL + router.asPath}
                    />
                ))}
            </Head>
            <JsonLdOrganization />
            <PostHogProvider client={posthog}>
                <AppCacheProvider
                    {...props}
                    emotionCache={getCache(language.direction as 'rtl' | 'ltr')}>
                    <ThemeProvider
                        theme={{
                            ...getTheme(language.direction as 'rtl' | 'ltr'),
                            components: {
                                ...getTheme(language.direction as 'rtl' | 'ltr').components,
                                MuiUseMediaQuery: {
                                    defaultProps: {
                                        ssrMatchMedia,
                                    },
                                },
                            },
                        }}>
                        <Provider store={store}>
                            <Global />
                            <Component {...pageProps} />
                        </Provider>
                    </ThemeProvider>
                </AppCacheProvider>
            </PostHogProvider>
        </>
    );
};

MyApp.getInitialProps = async (ctx: any): Promise<any> => {
    const initialProps = await App.getInitialProps(ctx);
    const userAgentValue = typeof window === 'undefined' ? ctx.ctx.req.headers['user-agent'] : window.navigator.userAgent;

    return { userAgentValue, ...initialProps };
};
export default MyApp;
