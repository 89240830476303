import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
export interface cartItemState {
    id: number | null;
}

// Define the initial state using that type
const initialState: cartItemState = {
    id: null,
};

export const cartItemSlice = createSlice({
    name: 'cartItem',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        cartItemSaveId: (state, action: PayloadAction<number>) => {
            state.id = action.payload;
        },
        cartItemClear: state => {
            state.id = null;
        },
    },
});

export const { cartItemSaveId, cartItemClear } = cartItemSlice.actions;

export default cartItemSlice.reducer;
