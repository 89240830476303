import { FlightSearchData } from 'components/flights/search-fields-flights/search-fields-flights.types';
import { SearchesFlightState } from './flight-searches-slice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TourSearch = {
    city: number;
    date: string;
};
export type recentSearchItemType = { data: TourSearch | SearchesFlightState | FlightSearchData; url: string; type: 'Tour' | 'Hotel' | 'Flight' };

export type recentSearchDataType = {
    recentSearches: recentSearchItemType[];
};

const initialState: recentSearchDataType = {
    recentSearches: [],
};

export const RecentSearchesSlice = createSlice({
    name: 'recentSearch',
    initialState,
    reducers: {
        addRecentSearch: (state, action: PayloadAction<recentSearchItemType>) => {
            if (state.recentSearches?.length > 10) {
                state.recentSearches?.shift();
            }
            state.recentSearches = [...state.recentSearches, action.payload];
        },
        clearRecentSearches: state => {
            state.recentSearches = [];
        },
    },
});

export const { addRecentSearch, clearRecentSearches } = RecentSearchesSlice.actions;

export default RecentSearchesSlice.reducer;
