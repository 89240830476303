import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { exchangeRateSaveRates, exchangeRateSetExpireDate, exchangeRateSetLoadingDate } from '../../../redux/exchange-rate-slice';
import { useAppSelector } from '../../../redux/hooks';
import { apiGetExchangeRates } from '../../../services/payment/payment';

export default function useUpdateRates() {
    const dispatch = useDispatch();
    const exchangeRate = useAppSelector(state => state.exchangeRate.exchangeRate);
    //const expireDate = 900000 //15 minutes
    const expireDate = 20000;
    const loadingDate = 10000; //10 seconds
    const isExpireDatePassed = (exchangeRate.expireDate || 0) < new Date().getTime();
    const isLoadingDatePassed = (exchangeRate.loadingDate || 0) < new Date().getTime();

    useEffect(() => {
        if (isExpireDatePassed && isLoadingDatePassed) {
            getExchangeRates();
        }
    }, [isExpireDatePassed, isLoadingDatePassed]);

    const getExchangeRates = async () => {
        dispatch(exchangeRateSetLoadingDate(new Date().getTime() + loadingDate));
        try {
            const { data } = await apiGetExchangeRates();
            dispatch(exchangeRateSaveRates(data));
            dispatch(exchangeRateSetExpireDate(new Date().getTime() + expireDate));
        } catch (err) {
            console.log(err);
        }
    };
}
