import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetCountriesResponse } from '../services/flights/flight.types';

// Define a type for the slice state
export interface countriesState {
    countries: GetCountriesResponse[] | null;
}

// Define the initial state using that type
const initialState: countriesState = {
    countries: null,
};

export const countriesSlice = createSlice({
    name: 'countries',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        countriesSave: (state, action: PayloadAction<GetCountriesResponse[]>) => {
            state.countries = action.payload;
        },
        countriesClear: state => {
            state.countries = null;
        },
    },
});

export const { countriesSave, countriesClear } = countriesSlice.actions;

export default countriesSlice.reducer;
