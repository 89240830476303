import { Api, Method } from '@travel-platform/commons/src/services/api';
import {
    AddBalanceRequest,
    AddBalanceResponse,
    ChangeCurrencyRequest,
    ChangeCurrencyResponse,
    CreateGatewaysRequest,
    CreateGatewaysResponse,
    CreateWalletRequest,
    CreateWalletResponse,
    DeleteGatewaysRequest,
    DeleteGatewaysResponse,
    GetCurrencyAvailableGatewaysRequest,
    GetExchangeRatesResponse,
    GetGatewaysRequest,
    GetGatewaysResponse,
    GetUserWalletBalanceResponse,
    GetUserWalletTransactionRequest,
    GetUserWalletTransactionResponse,
    IncreaseWalletBalanceRequest,
    IncreaseWalletBalanceResponse,
    ListUserBalanceRequest,
    ListUserBalanceResponse,
    UpdateCurrencyExchangeRateRequest,
    UpdateGatewaysRequest,
    UpdateGatewaysResponse,
} from './payment.types';

export const apiGetUserWalletBalance = (params: object | null, token: string | null) => {
    return Api<GetUserWalletBalanceResponse>(Method.GET, '/payment/wallet/balance', params, null, undefined, token);
};
export const apiIncreaseWalletBalance = (body: IncreaseWalletBalanceRequest | null, token: string | null) => {
    return Api<IncreaseWalletBalanceResponse>(Method.POST, '/payment/pay/?', null, body, undefined, token);
};
export const apiGetCurrencyAvailableGateways = (params: GetCurrencyAvailableGatewaysRequest | null, token?: string | null) => {
    return Api<GetGatewaysResponse>(Method.GET, '/payment/currency/gateways', params, null, undefined, token);
};
export const apiGetExchangeRates = () => {
    return Api<GetExchangeRatesResponse>(Method.GET, '/payment/exchange-rate', null, null);
};
export const apiListUserBalance = (params: ListUserBalanceRequest, token: string | null) => {
    return Api<ListUserBalanceResponse[]>(Method.GET, '/payment/wallet/admin/list-balances?', params, null, undefined, token);
};
export const apiAddBalance = (body: AddBalanceRequest, token: string | null) => {
    return Api<AddBalanceResponse>(Method.POST, '/payment/wallet/admin/deposit', null, body, undefined, token);
};
export const apiGetUserWalletTransaction = (params: GetUserWalletTransactionRequest, token: string | null) => {
    return Api<GetUserWalletTransactionResponse>(Method.GET, '/payment/wallet/transactions/?', params, null, undefined, token);
};
export const apiUpdateCurrencyExchangeRate = (body: UpdateCurrencyExchangeRateRequest, token: string | null) => {
    return Api<GetExchangeRatesResponse>(Method.PATCH, '/payment/exchange-rate', null, body, undefined, token);
};
export const apiCreateWallet = (body: CreateWalletRequest, token: string | null) => {
    return Api<CreateWalletResponse>(Method.POST, '/payment/wallet/admin/initial', null, body, undefined, token);
};
export const apiChangeCurrency = (body: ChangeCurrencyRequest, token: string | null) => {
    return Api<ChangeCurrencyResponse>(Method.POST, '/payment/wallet/admin/currency/change', null, body, undefined, token);
};
export const apiGetGateways = (params: GetGatewaysRequest, token: string | null) => {
    return Api<GetGatewaysResponse>(Method.GET, '/payment/admin/gateway/gateways', params, null, undefined, token);
};
export const apiCreateGateways = (body: CreateGatewaysRequest, token: string | null) => {
    return Api<CreateGatewaysResponse>(Method.POST, '/payment/admin/gateway', null, body, undefined, token);
};
export const apiUpdateGateways = (body: UpdateGatewaysRequest, token: string | null) => {
    return Api<UpdateGatewaysResponse>(Method.PUT, '/payment/admin/gateway', null, body, undefined, token);
};
export const apiDeleteGateways = (id: DeleteGatewaysRequest, token: string | null) => {
    return Api<DeleteGatewaysResponse>(Method.DELETE, `/payment/admin/gateway/${id}`, null, null, undefined, token);
};
