import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HotelSearchDataType } from '../components/hotels/search-fields-hotels/search-fields-hotels.types';

// Define a type for the slice state
export interface hotelSearchState {
    searchData: HotelSearchDataType | null;
    searchToken: string | null;
    expirationDate: number | null;
}

// Define the initial state using that type
const initialState: hotelSearchState = {
    searchData: null,
    searchToken: null,
    expirationDate: null,
};

export const hotelSearchSlice = createSlice({
    name: 'hotelSearch',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        hotelSearchSaveSearchData: (state, action: PayloadAction<HotelSearchDataType>) => {
            state.searchData = action.payload;
        },
        hotelSearchSaveSearchToken: (state, action: PayloadAction<string>) => {
            state.searchToken = action.payload;
        },
        hotelSearchSaveExpirationDate: (state, action: PayloadAction<number>) => {
            state.expirationDate = action.payload;
        },
        hotelSearchClearSearchData: state => {
            state.searchData = null;
        },
        hotelSearchClearSearchToken: state => {
            state.searchToken = null;
        },
        hotelSearchClearExpirationDate: state => {
            state.expirationDate = null;
        },
    },
});

export const { hotelSearchSaveSearchData, hotelSearchSaveSearchToken, hotelSearchSaveExpirationDate, hotelSearchClearSearchData, hotelSearchClearSearchToken, hotelSearchClearExpirationDate } = hotelSearchSlice.actions;

export default hotelSearchSlice.reducer;
