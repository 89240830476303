import { EnumTokenType, JwtDecodeType } from 'components/common/types/index.types';
import { deleteCookie, getCookie, hasCookie } from 'cookies-next';
import jwt_decode from 'jwt-decode';
import { apiRefreshToken } from '@travel-platform/commons/src/services/authentication/authentication';
import axios, { CancelTokenSource } from 'axios';
import { setTokenInCookie } from '@travel-platform/commons/src/components/login/functions';
import { login } from '@travel-platform/commons/src/redux/auth-slice';
import { store } from 'redux/store';

export const isExpiredToken = (token: string) => {
    const { exp }: JwtDecodeType = jwt_decode(token);
    const now = new Date().getTime();
    return exp * 1000 < now;
};

let refreshTokenCancelTokenSource: CancelTokenSource | null = null;
// A flag to avoid call refresh token multiple
let refreshTokenApiIsCalled: boolean = false;

export const needRefreshToken = async (): Promise<{
    state: 'waiting' | 'login' | 'logout';
    token: string | null;
}> => {
    const accessToken = getCookie(EnumTokenType.ACCESS_TOKEN);
    const refreshToken = getCookie(EnumTokenType.REFRESH_TOKEN);

    if (!hasCookie(EnumTokenType.ACCESS_TOKEN) || isExpiredToken(accessToken as string)) {
        if (hasCookie(EnumTokenType.REFRESH_TOKEN) && !isExpiredToken(refreshToken as string)) {
            if (refreshTokenApiIsCalled) {
                return { state: 'waiting', token: null };
            }

            // Set the flag true to avoid call refresh token again
            refreshTokenApiIsCalled = true;

            // Cancel previous refresh token apis
            if (refreshTokenCancelTokenSource) {
                refreshTokenCancelTokenSource.cancel('Canceled duplicate refresh token request.');
            }

            // Create a new cancel token source
            refreshTokenCancelTokenSource = axios.CancelToken.source();

            try {
                const { data } = await apiRefreshToken({ refreshToken: refreshToken as string }, refreshTokenCancelTokenSource.token);
                setTokenInCookie(data);
                store.dispatch(
                    login({
                        auth: {
                            ...data,
                            token: data.accessToken,
                        },
                    })
                );
                refreshTokenApiIsCalled = false;
                return {
                    state: 'login',
                    token: data.accessToken,
                };
            } catch (ex) {
                refreshTokenApiIsCalled = false;

                if (axios.isCancel(ex)) {
                    console.log('Refresh token request canceled:');
                } else {
                    return {
                        state: 'logout',
                        token: null,
                    };
                }
            }
        }
        return {
            state: 'logout',
            token: null,
        };
    }
    return {
        state: 'login',
        token: accessToken as string,
    };
};

export const logout = () => {
    deleteCookie(EnumTokenType.REFRESH_TOKEN);
    deleteCookie(EnumTokenType.ACCESS_TOKEN);
};
