import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FlightPassengerInformationStateReduxType } from '../services/authentication/authentication.types';
import { CountryCodeType } from '@travel-platform/commons/src/types/codeCountry';

// Define a type for the slice state
interface PassengersFlightSliceState {
    information: {
        [key: string]: {
            passengers: FlightPassengerInformationStateReduxType[];
            contacts: {
                email: string;
                mobile: string;
                countryCode: CountryCodeType;
            };
            isRuleAccepted: boolean;
        };
    } | null;
}

// Define the initial state using that type
const initialState: PassengersFlightSliceState = {
    information: null,
};

export const passengersFlightSlice = createSlice({
    name: 'flightPassengers',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        passengersFlightSave: (
            state,
            action: PayloadAction<{
                [key: string]: {
                    passengers: FlightPassengerInformationStateReduxType[];
                    contacts: {
                        email: string;
                        mobile: string;
                        countryCode: CountryCodeType;
                    };
                    isRuleAccepted: boolean;
                };
            } | null>
        ) => {
            state.information = action.payload;
        },
        passengersFlightClear: state => {
            state.information = null;
        },
    },
});

export const { passengersFlightSave, passengersFlightClear } = passengersFlightSlice.actions;

export default passengersFlightSlice.reducer;
