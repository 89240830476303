import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FlightSearchData } from '../components/flights/search-fields-flights/search-fields-flights.types';

// Define a type for the slice state
export interface SearchFlightState {
    flightSearch: FlightSearchData | null;
}

// Define the initial state using that type
const initialState: SearchFlightState = {
    flightSearch: null,
};

export const flightSearchSlice = createSlice({
    name: 'flightSearch',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        searchFlightSave: (state, action: PayloadAction<FlightSearchData>) => {
            state.flightSearch = action.payload;
        },
        searchFlightClear: state => {
            state.flightSearch = null;
        },
    },
});

export const { searchFlightSave, searchFlightClear } = flightSearchSlice.actions;

export default flightSearchSlice.reducer;
