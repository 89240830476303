import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Offer } from '../services/flights/flight.types';

// Define a type for the slice state
interface FlightOfferState {
    offer: Offer | null;
}

// Define the initial state using that type
const initialState: FlightOfferState = {
    offer: null,
};

export const flightOfferSlice = createSlice({
    name: 'flightOffer',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        offerSave: (state, action: PayloadAction<Offer>) => {
            state.offer = action.payload;
        },
        offerClear: state => {
            state.offer = null;
        },
    },
});

export const { offerSave, offerClear } = flightOfferSlice.actions;

export default flightOfferSlice.reducer;
