import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlaceTranslationResponse } from '../services/places/place-search-translate.types';

// Define a type for the slice state
export interface AirportsInfoState {
    airportsInfo: PlaceTranslationResponse[] | null;
}

// Define the initial state using that type
const initialState: AirportsInfoState = {
    airportsInfo: null,
};

export const airportsInfoSlice = createSlice({
    name: 'airportsInfo',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        airportsInfoSave: (state, action: PayloadAction<PlaceTranslationResponse[]>) => {
            if (state.airportsInfo === null) {
                state.airportsInfo = Array.from(new Set(action.payload));
            } else {
                state.airportsInfo = Array.from(new Set(state.airportsInfo.concat(action.payload)));
            }
        },
        airportsInfoClear: state => {
            state.airportsInfo = null;
        },
    },
});

export const { airportsInfoSave, airportsInfoClear } = airportsInfoSlice.actions;

export default airportsInfoSlice.reducer;
