import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetExchangeRatesResponse } from '../services/payment/payment.types';

// Define a type for the slice state
interface exchangeRateState {
    exchangeRate: {
        rates: GetExchangeRatesResponse | null;
        expireDate: number | null; //based on getTime(),
        loadingDate: number | null; //based on getTime() - to prevent call several times at one moment
    };
}

// Define the initial state using that type
const initialState: exchangeRateState = {
    exchangeRate: {
        rates: null,
        expireDate: null,
        loadingDate: null,
    },
};

export const exchangeRateSlice = createSlice({
    name: 'exchangeRate',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        exchangeRateSaveRates: (state, action: PayloadAction<GetExchangeRatesResponse>) => {
            state.exchangeRate.rates = action.payload;
        },
        exchangeRateSetLoadingDate: (state, action: PayloadAction<number>) => {
            state.exchangeRate.loadingDate = action.payload;
        },
        exchangeRateSetExpireDate: (state, action: PayloadAction<number>) => {
            state.exchangeRate.expireDate = action.payload;
        },
        exchangeRateClear: state => {
            state.exchangeRate.rates = null;
        },
    },
});

export const { exchangeRateSaveRates, exchangeRateClear, exchangeRateSetLoadingDate, exchangeRateSetExpireDate } = exchangeRateSlice.actions;

export default exchangeRateSlice.reducer;
