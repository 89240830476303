import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetHotelInfoResponse, GetHotelSearchResultResponse } from '../services/hotels/hotels.types';

// Define a type for the slice state
interface hotelInfoState {
    hotelDetails: GetHotelInfoResponse | null;
    roomsDetails: GetHotelSearchResultResponse | null;
}

// Define the initial state using that type
const initialState: hotelInfoState = {
    hotelDetails: null,
    roomsDetails: null,
};

export const hotelInfoSlice = createSlice({
    name: 'hotelInfo',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        hotelInfoSaveHotelDetails: (state, action: PayloadAction<GetHotelInfoResponse>) => {
            state.hotelDetails = action.payload;
        },
        hotelInfoSaveRoomDetails: (state, action: PayloadAction<GetHotelSearchResultResponse>) => {
            state.roomsDetails = action.payload;
        },
        hotelInfoClear: state => {
            state.hotelDetails = null;
            state.roomsDetails = null;
        },
    },
});

export const { hotelInfoSaveHotelDetails, hotelInfoSaveRoomDetails, hotelInfoClear } = hotelInfoSlice.actions;

export default hotelInfoSlice.reducer;
