import { Box } from '@mui/material';
import { LoginDialog } from '@travel-platform/commons/src/components/login';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { offerClear } from '../../../redux/flight-offer-slice';
import { useAppSelector } from '../../../redux/hooks';
import { hotelInfoClear } from '../../../redux/hotel-info-slice';
import { passengersFlightClear } from '../../../redux/passengers-flight-slice';
import { passengersHotelClear } from '../../../redux/passengers-hotel-slice';
import { PROFILE_PROJECT, ProfileProjectEnum } from '../constant/constant';
import useDevice, { DeviceEnum } from '../hook/use-device';
import useUpdateRates from '../hook/use-update-rates';
import SnackbarAlert from '@travel-platform/commons/src/components/common/snackbar-alert/snackbar-alert';
import WhatsappMassenger from '../whatsapp-massenger/whatsapp-massenger';
import { GlobalProps } from './global.types';
import { login, logout } from '@travel-platform/commons/src/redux/auth-slice';
import { apiGetProfile } from '@travel-platform/commons/src/services/authentication/authentication';
import { hasCookie, getCookie } from 'cookies-next';
import { EnumTokenType } from '../types/index.types';
import { LOCAL_STORAGE_LANG, defaultLanguage } from '@travel-platform/commons/src/components/common/types/index.types';
import styles from './global.module.scss';
// import { isIOS } from 'react-device-detect';
import { needRefreshToken } from 'services/auth/auth';
// import flows from '@travel-platform/commons/src/components/login/constants/flows';

export default function Global(props: GlobalProps) {
    useUpdateRates();
    const router = useRouter();
    const device = useDevice();
    const dispatch = useDispatch();
    const { flightOffer, passengersFlight, passengersHotel, hotelInfo, auth } = useAppSelector(state => state);
    const isVisitOurIran = PROFILE_PROJECT === ProfileProjectEnum.VISITOURIRAN;
    const isProduction = PROFILE_PROJECT === ProfileProjectEnum.PRODUCTION;
    const isSafratBeta = PROFILE_PROJECT === ProfileProjectEnum.BETA;
    // const forceLogin = ((isSafratBeta || isProduction) && !isIOS && !router.asPath.includes('/support')) || (PROFILE_PROJECT === 'VISITOURIRAN' && router.asPath.split('#')[1] === 'forceLogin');
    // const neverLogin = isVisitOurIran;
    // const { firstStep } = useAppSelector(state => state.login);

    useEffect(() => {
        const hasAuthCookie = hasCookie(EnumTokenType.ACCESS_TOKEN as string);

        checkLoginStrategy();

        if (hasAuthCookie) {
            const authCookie = getCookie(EnumTokenType.ACCESS_TOKEN) as string;

            dispatch(
                login({
                    auth: {
                        token: authCookie,
                    },
                })
            );

            (async () => {
                const { data } = await apiGetProfile(authCookie);
                dispatch(
                    login({
                        auth: {
                            token: authCookie,
                            ...data,
                        },
                    })
                );
            })();
        }
    }, [router.pathname]);
    const checkLoginStrategy = async () => {
        const resultOfCheckTokenState = await needRefreshToken();
        if (resultOfCheckTokenState.state === 'logout') {
            // if (forceLogin) router.push({ pathname: '/login', query: { current: flows[firstStep], returnUrl: router.query.returnUrl ? router.query.returnUrl : encodeURIComponent(router.asPath) } });
            dispatch(logout());
        }
    };

    // set locale into local storage
    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_LANG, defaultLanguage);

        if (router.locale) {
            localStorage.setItem(LOCAL_STORAGE_LANG, router.locale);
        }
    }, [router.locale]);

    useEffect(() => {
        const pathname = router.pathname.split('/');
        if (pathname.length > 0) {
            clearSessionStorage(pathname);
        }
    }, [router.pathname]);

    function clearSessionStorage(pathname: string[]) {
        const currentPage = pathname[pathname.length - 1];
        const pagesWhileList = ['passengers-information', 'confirm-information'];
        if (!pagesWhileList.includes(currentPage)) {
            if (flightOffer) {
                dispatch(offerClear());
            }
            if (passengersFlight.information) {
                dispatch(passengersFlightClear());
            }
            if (passengersHotel.information) {
                dispatch(passengersHotelClear());
            }
            if (hotelInfo.hotelDetails || hotelInfo.roomsDetails) {
                dispatch(hotelInfoClear());
            }
        }
    }
    const isMobile = device === DeviceEnum.MOBILE;
    const isDesktop = device === DeviceEnum.DESKTOP;

    return (
        <>
            <noscript
                dangerouslySetInnerHTML={{
                    __html: `<iframe
                    src='https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM}'
                    height='0'
                    width='0'
                    style='display:none;visibility:hidden'></iframe>`,
                }}></noscript>
            <SnackbarAlert />
            <LoginDialog />
            {!isMobile && (
                <Box
                    position='fixed'
                    zIndex={2147483000}
                    display='flex'
                    className={styles.whatsappMassenger}
                    bottom={{ xs: 16, md: 16 }}
                    sx={{ cursor: 'pointer' }}>
                    <WhatsappMassenger />
                </Box>
            )}
        </>
    );
}
