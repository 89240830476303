import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PassengerInfoRoom } from '../components/hotels/passengers-information/passengers-information.types';
import { RoomType } from '../services/hotels/hotels.types';
import { CountryCodeType } from '@travel-platform/commons/src/types/codeCountry';

// Define a type for the slice state
export type PassengerHotelInformationType = {
    rooms: {
        details: RoomType;
        passengers: PassengerInfoRoom;
    }[];
    contacts: {
        email: string;
        mobile: string;
        countryCode: CountryCodeType;
    };
    isRuleAccepted: boolean;
};
interface PassengersHotelSliceState {
    information: PassengerHotelInformationType | null;
}

// Define the initial state using that type
const initialState: PassengersHotelSliceState = {
    information: null,
};

export const passengersHotelSlice = createSlice({
    name: 'passengersHotel',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        passengersHotelSave: (state, action: PayloadAction<PassengerHotelInformationType | null>) => {
            state.information = action.payload;
        },
        passengersHotelClear: state => {
            state.information = null;
        },
    },
});

export const { passengersHotelSave, passengersHotelClear } = passengersHotelSlice.actions;

export default passengersHotelSlice.reducer;
