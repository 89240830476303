import { Box } from '@mui/material';
import Link from 'next/link';
import { PROFILE_PROJECT } from '../constant/constant';
import styles from './whatsapp-massenger.module.scss';
import { WhatsappMassengerProps } from './whatsapp-massenger.types';

export default function WhatsappMassenger(props: WhatsappMassengerProps) {
    const isVisitOurIran = PROFILE_PROJECT === 'VISITOURIRAN';
    const linkWhatsapp = isVisitOurIran ? `https://wa.me/+${process.env.NEXT_PUBLIC_VISITOURIRAN_WHATSAPP_NUMBER}` : `https://wa.me/+${process.env.NEXT_PUBLIC_SAFRAT_WHATSAPP_NUMBER}`;
    return (
        <Box className={styles.whatsapp + ' whatsapp'}>
            <Link
                href={linkWhatsapp}
                passHref>
                <Box
                    sx={{
                        color: 'white',
                        backgroundColor: '#30BF39',
                        position: 'relative',
                        width: '50px',
                        height: '50px',
                        borderRadius: 9999,
                        boxShadow: '0 8px 24px rgba(0, 0, 0, 0.16)',
                        '&:hover': {
                            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
                        },
                    }}>
                    <Box
                        sx={{
                            top: '50%',
                            left: '50%',
                            transform: ' translate(-50%,-50%)',
                            position: 'absolute',
                            fontSize: 28,
                        }}
                        className='icon-whatsapp'></Box>
                </Box>
            </Link>
        </Box>
    );
}
