import { useMediaQuery, useTheme } from '@mui/material';

export enum DeviceEnum {
    MOBILE = 'mobile',
    TABLET = 'tablet',
    DESKTOP = 'desktop',
}
export default function useDevice() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    if (isMobile) return DeviceEnum.MOBILE;
    else if (isDesktop) return DeviceEnum.DESKTOP;
    else return DeviceEnum.TABLET;
}
