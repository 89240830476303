import { Role } from '../access/Role/Role';
import { PermissionType } from '../access/permissions/permissions';

export enum EnumTokenType {
    ACCESS_TOKEN = 'ACCESS_TOKEN',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
}
export type JwtDecodeType = {
    role: Role;
    permissions: PermissionType[];
    mobile: string;
    userId: number;
    storeId: number;
    exp: number;
    sub: EnumTokenType;
};

export enum EnumGender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
}
export type GenderType = EnumGender.MALE | EnumGender.FEMALE;
