import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FlightSearchData } from '../components/flights/search-fields-flights/search-fields-flights.types';

// Define a type for the slice state
export interface SearchesFlightState {
    flightSearches:
        | {
              data: FlightSearchData;
              url: string;
          }[]
        | null;
}

// Define the initial state using that type
const initialState: SearchesFlightState = {
    flightSearches: null,
};

export const flightSearchesSlice = createSlice({
    name: 'flightSearches',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        searchesFlightSave: (state, action: PayloadAction<{ data: FlightSearchData; url: string }>) => {
            if (state.flightSearches) {
                if (state.flightSearches.length > 5) {
                    const copyState = [...state.flightSearches];
                    copyState.splice(0, 1);
                    state.flightSearches = [...copyState, action.payload];
                } else {
                    state.flightSearches = [...state.flightSearches, action.payload];
                }
            } else {
                state.flightSearches = [action.payload];
            }
        },
        searchesFlightClear: state => {
            state.flightSearches = [];
        },
    },
});

export const { searchesFlightSave, searchesFlightClear } = flightSearchesSlice.actions;

export default flightSearchesSlice.reducer;
