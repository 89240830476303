import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AirlineTranslateResponse } from '../services/places/place-search-translate.types';

// Define a type for the slice state
export interface AirlineInfoState {
    airlinesInfo: AirlineTranslateResponse[] | null;
}

// Define the initial state using that type
const initialState: AirlineInfoState = {
    airlinesInfo: null,
};

export const airlinesInfoSlice = createSlice({
    name: 'airlinesInfo',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        airlinesInfoSave: (state, action: PayloadAction<AirlineTranslateResponse[]>) => {
            if (state.airlinesInfo === null) {
                state.airlinesInfo = Array.from(new Set(action.payload));
            } else {
                state.airlinesInfo = Array.from(new Set(state.airlinesInfo.concat(action.payload)));
            }
        },
        airlinesInfoClear: state => {
            state.airlinesInfo = null;
        },
    },
});

export const { airlinesInfoSave, airlinesInfoClear } = airlinesInfoSlice.actions;

export default airlinesInfoSlice.reducer;
