import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HotelSearchDataType } from '../components/hotels/search-fields-hotels/search-fields-hotels.types';

// Define a type for the slice state
export interface hotelSearchesState {
    hotelSearchs:
        | {
              data: HotelSearchDataType;
              url: string;
          }[]
        | null;
}

// Define the initial state using that type
const initialState: hotelSearchesState = {
    hotelSearchs: null,
};

export const hotelSearchesSlice = createSlice({
    name: 'hotelSearches',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        hotelSearchesSave: (
            state,
            action: PayloadAction<{
                data: HotelSearchDataType;
                url: string;
            }>
        ) => {
            if (state.hotelSearchs)
                if (state.hotelSearchs.length > 5) {
                    const copyState = [...state.hotelSearchs];
                    copyState.splice(0, 1);
                    state.hotelSearchs = [...copyState, action.payload];
                } else {
                    state.hotelSearchs = [...state.hotelSearchs, action.payload];
                }
            else state.hotelSearchs = [action.payload];
        },
        hotelSearchesClear: state => {
            state.hotelSearchs = null;
        },
    },
});

export const { hotelSearchesSave, hotelSearchesClear } = hotelSearchesSlice.actions;

export default hotelSearchesSlice.reducer;
