import { FlightInformationSegment } from '../../components/dashboard/track-order/components/ticket-issuance/component/flight-information-fields/flight-information-fields.types';
import { GenderType } from '../../components/common/types/index.types';

export enum EnumPassenger {
    ADULT = 'ADULT',
    CHILD = 'CHILD',
    INFANT = 'INFANT',
}

export type PassengerType = EnumPassenger.ADULT | EnumPassenger.CHILD | EnumPassenger.INFANT;

export enum SupportedCurrency {
    USD = 'USD',
    IRR = 'IRR',
    IQD = 'IQD',
    SAR = 'SAR',
    EUR = 'EUR',
    GBP = 'GBP',
    AED = 'AED',
}

export type SupportedCurrencyType = SupportedCurrency.USD | SupportedCurrency.IRR | SupportedCurrency.IQD | SupportedCurrency.SAR | SupportedCurrency.EUR | SupportedCurrency.GBP | SupportedCurrency.AED;
export type PriceCurrency = Partial<Record<SupportedCurrency, number>>;
export type FlightSegment = {
    origin: string;
    destination: string;
    departureDateTime: string;
    arrivalDateTime: string;
    airlineCode: string;
    airlineLogo: string;
    flightNumber: string | number;
    duration: number;
    airplaneCode: string | null;
    operatingAirlineCode: string;
    remainingSeats: number;
    baggage: string;
    resBookDesigCode: string;
    isChartered: boolean;
    connectionTime: number;
    // cabinClass: string;
};
export type Path = {
    segments: FlightSegment[];
    totalDuration: number;
    totalConnectionTime: number;
};
export type Offer = {
    offerId: string;
    paths: Path[];
    payablePrice: PayablePrice;
    providerDetail: {
        providerId: number;
        type: string;
        name: string;
        reference: string;
        providerPrice?: {
            fareType: string;
            priceDetail: {
                totalPrice: number;
                basePrice: number;
                tax: number;
                commission: number;
                currency: SupportedCurrency;
                serviceTax: number;
            };
            priceInfoDetail: [
                {
                    price: number;
                    basePrice: number;
                    tax: number;
                    commission: number;
                    currency: SupportedCurrency;
                    passengerQuantity: number;
                    passengerType: string;
                }
            ];
        };
    };
};
export type PayablePrice = {
    totalPrice: number;
    priceInfo: PriceInfo[];
};
export type PriceInfo = {
    passengerQuantity: number;
    totalDiscount: number;
    basePrice: number;
    totalPrice: number;
    passengerType: EnumPassenger;
};

export enum EnumFlight {
    ONEWAY = 'ONEWAY',
    RETURN = 'RETURN',
    MULTI_CITY = 'MULTI_CITY',
}

export type FlightType = EnumFlight.ONEWAY | EnumFlight.RETURN | EnumFlight.MULTI_CITY;

export enum CabinClass {
    ECONOMY = 'ECONOMY',
    BUSINESS = 'BUSINESS',
    FIRST = 'FIRST',
}

export enum LocationType {
    CITY = 'CITY',
    AIRPORT = 'AIRPORT',
}

export enum RefundMethod {
    OFFLINE = 0,
    ONLINE = 1,
    NON_REFUNDABLE = 2,
}

export type SearchFlightsRequest = {
    flightInfo: {
        flightType: FlightType;
        cabinClass: CabinClass;
        originDestinationInfo: {
            departureDate: `${string}-${string}-${string}`;
            originCode: string;
            originType: LocationType;
            destinationCode: string;
            destinationType: LocationType;
        }[];
    };
    passengerInfo: {
        adultCount: number;
        childCount: number;
        infantCount: number;
    };
};
export type SearchFlightsResponse = {
    searchToken: string;
    expireAt: string;
};
export type GetFlightsRequest = {
    searchToken: string;
};
export type GetFlightsResponse = {
    offers: Offer[];
};
export type GetOfferRequest = {
    offerId: string;
};
export type GetOfferResponse = {
    offer: Offer;
    status: boolean;
};
export type GetRulesRequest = {
    offerId: string;
};
export type GetRulesResponse = {
    airline: string;
    cityPair: string;
    ruleDetails: [
        {
            category: string;
            rules: string;
        }
    ];
};
export type GetCountriesResponse = {
    calling_code: string | null;
    code: string;
    flag_url: string | null;
    country_names: Array<{
        language: string;
        value: string;
    }>;
};
export type GetTicketDetailRequest = {
    offerIds: string[];
};
export type GetTicketDetailResponse = {
    offerId: string;
    createdAt: string;
    id: number;
    providerInfo?: ProviderInfo;
    tripTicketDetail: TripTicketDetail;
    passengers: PassengerTicketDetail[];
    token: string;
};

export type PassengersInformationFieldsProps = {
    data: PassengersInformationSegment;
    direction: string;
    addPassengersInformation: (id: number, key: string, value: string | number, ticketIndex?: number) => void;
    removePassengersInformation: (id: number) => void;
    countriesOption?: any;
};
export type PassengersInformationTicketsSegment = {
    id: number;
    ticketNumber: string;
    isRefunded?: boolean;
    issuedDate?: string;
    totalRefund?: number;
    airlinePnr?: string;
    status?: string;
};
export type PassengersInformationSegment = {
    id: number;
    gender: GenderType | 'default';
    passengerType: PassengerType | 'default';
    passportNumber: string;
    nationalId?: string;
    nationality: string;
    passportExpireDate: string;
    passportIssuedDate: string;
    passportIssuedCountry?: string;
    firstName: string;
    middleName: string;
    lastName: string;
    nameTitle?: string;
    birthdate: string;
    tickets: PassengersInformationTicketsSegment[];
};
export type PriceInfoType = {
    basePrice?: number;
    passengerQuantity: number;
    passengerType: PassengerType;
    profitPrice?: number;
    totalDiscount?: number;
    totalPrice: string | number;
};
export type ReceiptPaymentPayablePriceType = {
    totalPrice: number;
    priceModifiers: {
        discount: {
            id?: number;
            role: null;
            userId: number;
            discountPercentage: number;
        };
        exchangeRate: {} | null;
        markup: {} | null;
    };
    priceInfo: PriceInfoType[];
};
export type ReceiptPaymentType = {
    payablePrice: ReceiptPaymentPayablePriceType;
    refundMethod?: number;
    flightType: FlightType;
    tripItems: FlightInformationSegment[];
    priceMeta: {};
};
export type CreateTicketFormRequest = {
    targetUserId: number;
    tripTicket: {
        contactEmail: string;
        contactMobile: string;
        providerReference?: string;
        passengers: PassengersInformationSegment[];
        tripTicketDetail: ReceiptPaymentType;
    };
};

export type CreateTicketFormResponse = {
    contactEmail: string;
    contactMobile: string;
    createdAt: Date;
    id: number;
    offerId: number;
    providerId: number;
    providerReference: string;
    status: string;
    storeId: number;
    tktTimeLimit: number;
    updatedAt: Date;
    url: string;
    userId: number;
};
export type ProviderInfo = { id: number; name: string; type: string; profitPercentage: number };
export type TripTicketDetail = {
    payablePrice: {
        priceInfo: PriceInfo[];
        totalPrice: number;
    };
    providerPrice?: {
        fareType: string;
        priceDetail: {
            tax: number;
            currency: SupportedCurrency;
            basePrice: number;
            commission: number;
            serviceTax: number;
            totalPrice: number;
        };
        priceInfoDetail: [
            {
                tax: number;
                price: number;
                currency: SupportedCurrency;
                basePrice: number;
                commission: number;
                passengerType: EnumPassenger;
                passengerQuantity: number;
            }
        ];
    };
    flightType: string;
    tripItems: TripItem[];
};
export type PassengerTicketDetail = {
    firstName: string;
    middleName: string;
    lastName: string;
    birthdate: string;
    gender: GenderType;
    passportNumber: string;
    passengerType: string;
    tickets: PassengerTicket[];
};
export type PassengerTicket = {
    airlinePnr: string | null;
    id: number;
    isRefunded: boolean;
    issuedDate: string;
    passengerId: number;
    status: string;
    ticketNumber: string;
    totalRefund: string;
};
export type TripItem = {
    baggage: string;
    duration: number;
    airlinePnr: null;
    cabinClass: string;
    isChartered: boolean;
    airplaneCode: string;
    flightNumber: string;
    stopQuantity: number;
    TechnicalStops: Array<any>;
    arrivalAirport: string;
    arrivalDateTime: string;
    arrivalTerminal: string;
    departureAirport: string;
    resBookDesigCode: string;
    departureDateTime: string;
    departureTerminal: string;
    marketingAirlineCode: string;
    operatingAirlineCode: string;
};
export type GetUserDiscountResponse = {
    discountPercentage: number;
};
export type GetRoleDiscountsResponse = {
    role: string;
    discountPercentage: number | string;
};
export type ListUserDiscountsRequest = {
    userIds: number[];
};
export type ListUserDiscountsResponse = {
    userId: number;
    discountPercentage: number;
};
export type SetUserDiscountRequest = {
    userId: number;
    discountPercentage: number | string;
    role?: string;
    [key: string]: any;
};
export type SetUserDiscountResponse = {
    id: number;
    userId: number;
    discountPercentage: number | string;
    role?: string;
};
export type ProviderPriceSetting = {
    provider: string;
    profitPercentage: number;
};
export type SetProviderPriceSettingRequest = {
    priceSettings: ProviderPriceSetting[];
};
export type SetProviderPriceSettingResponse = {
    id: number;
    profitPercentage: number;
    provider: string;
};
export type ProviderAccountBalanceResponse = {
    providerId: number;
    balance: Partial<Record<SupportedCurrency, number>>;
};
export type CreateAProductPassengerType = {
    firstName: string;
    lastName: string;
    passengerType: PassengerType;
    middleName: string;
    gender: string;
    birthdate: string;
    nationalId?: string | number;
    nationality: string;
    passportIssuedDate: string;
    passportExpireDate: string;
    passportIssuedCountry: string;
    passportNumber: string;
};
export type CreateAProductRequest = {
    offerId: string;
    passengers: CreateAProductPassengerType[];
    contactMobile: string;
    contactEmail?: string;
};

export type CreateAProductResponse = {
    productId: number;
};
export type MarkupRule = {
    id: number;
    index: number;
    profitPercentage: number;
    providerId: number | null;
    origin: string | null;
    destination: string | null;
    airline: string | null;
    disabledAt: string | null;
    from: string | null;
    to: string | null;
    originType: LocationType | null;
    destinationType: LocationType | null;
};
export type GetMarkupRulesRequest = {
    page: number;
    perPage: number;
};
export type GetMarkupRulesResponse = {
    result: MarkupRule[];
    count: number;
    page: number;
    perPage: number;
};
export type UpdateMarkupRulesRequest = {
    index?: number;
    profitPercentage?: number;
    providerId?: number;
    origin?: string;
    originType?: string;
    destination?: string;
    destinationType?: string;
    airline?: string;
    from?: string;
    to?: string;
};

export type CreateMarkupRulesRequest = {
    index: number;
    profitPercentage: number;
    providerId?: number;
    origin?: string;
    originType?: string;
    destination?: string;
    destinationType?: string;
    airline?: string;
    from?: string;
    to?: string;
};
export type GetProvidersRequest = {
    take: number;
    skip: number;
};
export type GetProvidersResponse = {
    id: number;
    name: string;
    type: string;
    config: {
        [key: string]: any;
    };
    profitPercentage: number;
    isActive: boolean;
    storeId: number;
};
export type UpdateProvidersRequest = {
    profitPercentage: number;
    name: string;
    type: string;
    isActive: boolean;
    config: {
        [key: string]: any;
    };
};
export type GetProvidersTypesResponse = string[];
export type GetTicketDetailByJwtRequest = {
    jwtId: string;
};
export type GetTicketDetailByJwtResponse = {
    offerId: string;
    createdAt: string;
    id: number;
    providerInfo?: ProviderInfo;
    tripTicketDetail: TripTicketDetail;
    passengers: PassengerTicketDetail[];
};
export type RemoveUserDiscountRequest = {
    userId: number;
};
export type RemoveUserDiscountResponse = {
    id: number;
    role: string;
    userId: number;
    discountPercentage: number;
};
export type GetDownloadPdfResponse = {
    url: string;
};
export type GetAirlineSearchRequest = {
    search: string;
};
export type AirlineNamesSearch = {
    language: string;
    value: string;
};
export type GetAirlineSearchResponse = {
    id: number;
    code: string;
    logo_url: string;
    airline_names: AirlineNamesSearch[];
};
