import { OrganizationJsonLd } from 'next-seo';
import { PROFILE_PROJECT } from '@travel-platform/commons/src/components/common/constant/constant';

const JsonLdOrganization = () => {
    const jsonLd = {
        jsonLd__PRODUCTION: {
            '@context': 'https://schema.org',
            '@type': ['TravelAgency', 'Organization'],
            name: 'Safrat',
            alternateName: 'سفرات',
            url: 'https://safrat.me',
            logo: 'https://safrat.me/logo-PRODUCTION-header.svg',
            contactPoint: [
                {
                    '@type': 'ContactPoint',
                    telephone: process.env.NEXT_PUBLIC_SAFRAT_WHATSAPP_NUMBER,
                    contactType: 'customer service',
                    areaServed: 'IQ',
                    availableLanguage: 'Arabic',
                },
            ],
            sameAs: ['https://www.instagram.com/safrat.me/', 'https://wa.me/+9647854041530', 'https://www.youtube.com/@visitouriran', 'https://www.facebook.com/visitouriran/'],
            address: {
                '@type': 'PostalAddress',
                streetAddress: 'زيونة شارع الربيعي',
                addressLocality: 'Baghdad',
                addressCountry: 'Iraq',
            },
        },
        jsonLd__VISITOURIRAN: {
            '@context': 'https://schema.org',
            '@type': ['TravelAgency', 'Organization'],
            name: 'VisitOurIran',
            url: 'https://www.visitouriran.com',
            logo: 'https://www.visitouriran.com/booking/logo-VISITOURIRAN-header.svg',
            contactPoint: [
                {
                    '@type': 'ContactPoint',
                    telephone: '+982188553494',
                    contactType: 'customer service',
                    areaServed: 'IR',
                    availableLanguage: 'English',
                },
                {
                    '@type': 'ContactPoint',
                    telephone: process.env.NEXT_PUBLIC_VISITOURIRAN_WHATSAPP_NUMBER,
                    contactType: 'customer service',
                    areaServed: 'IR',
                    availableLanguage: 'English',
                },
            ],
            sameAs: ['https://www.instagram.com/visitouriran/', 'https://twitter.com/VisitOurIran/', 'https://www.youtube.com/@visitouriran', 'https://www.facebook.com/visitouriran/'],
            address: {
                '@type': 'PostalAddress',
                streetAddress: 'Vozara',
                addressLocality: 'Tehran',
                addressCountry: 'Iran',
            },
        },
    };

    return <OrganizationJsonLd {...jsonLd[`jsonLd__${PROFILE_PROJECT}` as keyof typeof jsonLd]} />;
};
export default JsonLdOrganization;
